import {
  brand,
  primaryFamily,
  Eyebrow,
  Container as DSContainer,
  spacing,
} from '@pelotoncycle/design-system';
import { getOutcomeInterestData, getModalityData } from '@pelotoncycle/page-builder';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { privacy as privacyPath } from '@peloton/links/wwwPaths';
import { media } from '@peloton/styles/breakpoints';
import { InlineLink } from '@ecomm/links/links';
import Markdown from '@page-builder/utils/Markdown';
import EmailCapture from './EmailCapture';

type Props = {
  id: string;
  campaignId?: string;
  campaignName?: string;
  country: string;
  path?: string;
  productInterest?: string;
  headlineText: string;
  ctaText: string;
  supportText: string;
  placeholder: string;
  emailCaptureSuccess: string;
  emailCaptureFailure: string;
};

const isBikeRoute = (path = ''): boolean => path.includes('bike');
const isTreadRoute = (path = ''): boolean => path.includes('tread');
const isDigitalRoute = (path = ''): boolean => path.includes('digital');

const routeToProductInterest = (path = '') => {
  switch (true) {
    case isBikeRoute(path):
      return 'bike';
    case isTreadRoute(path):
      return 'tread';
    case isDigitalRoute(path):
      return 'digital';
    default:
      return '';
  }
};

const View: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  campaignId,
  campaignName,
  country,
  path,
  productInterest,
  ctaText,
  placeholder,
  emailCaptureSuccess,
  emailCaptureFailure,
  headlineText,
  supportText,
}) => {
  const outcomeInterest = getOutcomeInterestData();
  const modality = getModalityData();
  const defaultPromoCampaign = {
    id: '7011C000001QeUUQA0',
    name: 'Email Capture Unit',
  };

  const trackingProps = {
    campaignId: campaignId ? campaignId : defaultPromoCampaign.id,
    campaignName: campaignName ? campaignName : defaultPromoCampaign.name,
    country,
    marketingConsent: 'explicit',
    pageName: path,
    productInterest: productInterest || routeToProductInterest(path),
    propertyType: 'Web',
    unitName: `Footer ${defaultPromoCampaign.name}`,
    unitPlacement: 'global footer',
    ...(outcomeInterest && { outcomeInterest }),
    ...(modality && { modality }),
  };

  const { Track } = useTracking({
    event: 'Submitted Email for Marketing',
    properties: trackingProps,
  });

  return (
    <InputContainer>
      <Container>
        <DSContainer verticalMargin={`0 ${spacing[16]}`}>
          <Eyebrow size="medium" id="footer-lead-capture-headline">
            {headlineText}
          </Eyebrow>
        </DSContainer>
        <Track>
          <EmailCapture
            ctaText={ctaText}
            failureMessage={emailCaptureFailure}
            inputId={trackingProps.unitName}
            placeholder={placeholder}
            successMessage={emailCaptureSuccess}
          />
        </Track>
      </Container>
      <PrivacyNotice>
        <Markdown
          content={supportText}
          values={{ privacyPolicyLink: privacyPath }}
          markdown={{
            renderers: {
              link: InlineLink,
            },
          }}
        />
      </PrivacyNotice>
    </InputContainer>
  );
};

const InputContainer = styled.div`
  ${media.tablet`
    margin-bottom: 30px;
    width: 100%;
    max-width: 386px;
  `}

  // targeting the error message font color
  // targetting just span would alter the placeholder font color
  label > span {
    color: ${brand.darkest};
  }
`;

const Container = styled.div`
  ${media.tablet`
    width: 100%;
    max-width: 386px;
  `}
`;

const PrivacyNotice = styled.span`
  color: ${brand.darkest};
  display: block;
  font-weight: 300;
  font-size: 12px;
  font-family: ${primaryFamily};
  letter-spacing: 0.025em;
  line-height: 1.5em;
`;

export default View;
