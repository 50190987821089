import type { ExtLinkEnv } from '@peloton/external-links';
import { toApiLink, toHref, toWWWLink } from '@peloton/external-links';
import { Country, Currency, provinceToShortcode } from '@peloton/internationalize';
import type { Cart, SingleItem } from '@ecomm/cart/models';
import {
  getSingleItemsFromCart,
  recalculateRemainingTotal,
  toPrimaryImage,
  toShipmentTotal,
} from '@ecomm/cart/models';
import type { Address, ContactInfo, Email } from '@ecomm/models';
import type { CheckoutPayload } from '../models/CheckoutPayload';
import type {
  AffirmCheckoutCartInfo,
  AffirmCheckoutItem,
  AffirmContact,
  AffirmErrorHandler,
  AffirmMetaInfo,
} from './api';
import { checkout as affirmCheckout } from './api';
import { toCancelUrl, toConfirmPath } from './callbackURLs';
import { MERCHANT_NAME } from './config';

export const checkout = ({
  billing,
  cart,
  email,
  extLinkEnv,
  financingProgram,
  shipping,
  onError,
  isGiftCardEnabled,
  overrideCancelUrl,
  overrideConfirmationUrl,
}: CheckoutPayload & {
  isGiftCardEnabled: boolean;
  financingProgram?: string;
  onError: AffirmErrorHandler;
}) => {
  const isPaymentsListExists = cart.payments && cart.payments.length !== 0;

  const total =
    isGiftCardEnabled && isPaymentsListExists
      ? recalculateRemainingTotal(cart.total, cart.payments)
      : cart.total;

  affirmCheckout(
    {
      ...toCheckoutCartInfo({ ...cart, ...{ total } }, extLinkEnv),
      ...toCheckoutMetaInfo(
        cart,
        extLinkEnv,
        financingProgram,
        overrideCancelUrl,
        overrideConfirmationUrl,
      ),
      billing: toAffirmContact(billing, email),
      shipping: toAffirmContact(shipping, email),
    },
    onError,
  );
};

const toCheckoutCartInfo = (
  cart: Cart,
  extLinkEnv: ExtLinkEnv,
): AffirmCheckoutCartInfo => {
  if (cart) {
    const singleItems = getSingleItemsFromCart(cart);

    return {
      items: singleItems.map(toCheckoutItem(extLinkEnv)),
      shippingAmount: toShipmentTotal(cart),
      taxAmount: cart.tax,
      total: cart.total,
    };
  } else {
    return {
      items: [],
      shippingAmount: 0,
      taxAmount: 0,
      total: 0,
    };
  }
};

const toCheckoutItem = (extLinkEnv: ExtLinkEnv) => (
  item: SingleItem,
): AffirmCheckoutItem => ({
  displayName: item.name,
  itemImageUrl: toPrimaryImage(item),
  itemUrl: toHref(toWWWLink('/shop'), extLinkEnv), // TODO: go to actual item?
  sku: item.sku,
  qty: item.quantity || 1,
  unitPrice: item.price,
});

const toCheckoutMetaInfo = (
  cart: Cart,
  extLinkEnv: ExtLinkEnv,
  financingProgram?: string,
  overrideCancelUrl?: string,
  overrideConfirmationUrl?: string,
): AffirmMetaInfo => {
  const isUS = cart.currency === Currency.UnitedStatesDollars;

  const userCancelUrl = overrideCancelUrl ?? toCancelUrl();
  const userConfirmationUrl =
    overrideConfirmationUrl ?? toHref(toApiLink(toConfirmPath(cart.id)), extLinkEnv);

  return {
    financingProgram: isUS ? financingProgram : undefined,
    currency: isUS ? undefined : cart.currency,
    merchant: {
      name: MERCHANT_NAME,
      userCancelUrl,
      userConfirmationUrl,
    },
  };
};

const toAffirmContact = (info: ContactInfo, email: Email): AffirmContact => ({
  address: toAffirmContactAddress(info.address),
  email,
  name: info.name,
});

const toAffirmCountry = (country: string) => {
  switch (country) {
    case Country.Australia:
      return 'AUS';
    case Country.Canada:
      return 'CA';
    default:
      return 'USA';
  }
};

export const toAffirmRegion1 = (country: string, state: string) =>
  country === Country.Canada ? provinceToShortcode[state] : state;

export const toAffirmContactAddress = ({
  country,
  state,
  postalCode,
  line1,
  line2,
  ...address
}: Address) => ({
  ...address,
  street1: line1,
  street2: line2,
  region1_code: toAffirmRegion1(country, state),
  postal_code: postalCode,
  country: toAffirmCountry(country),
});
