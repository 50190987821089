import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import type { Locale } from '@peloton/internationalize/models/locale';
import { toLocaleFromHostname } from '@peloton/internationalize/models/locale';
import { stores } from '@ecomm/build-time-catalog/stores';
import { GetCustomObjectByKeyDocument } from '@ecomm/commercetools/queries/GetCustomObjectByKey.generated';
import fromLocale from '@ecomm/graphql-bridge/Locale/fromLocale';
import type { AvailabilityState } from '@ecomm/product-states/models/availability';
import type { AvailabilityByProduct } from '@ecomm/product-states/models/context';
import { Product } from '@ecomm/product-states/models/product';
import { ProductStates } from '@ecomm/product-states/models/productState';

const UNAVAILABLE = 0;

const UNAVAILABLE_STATE: AvailabilityState = {
  state: ProductStates.Unavailable,
  code: UNAVAILABLE,
};

const productLineToProduct = {
  BIKE: Product.Bike,
  BIKEPLUS: Product.BikePlus,
  TREAD: Product.Tread,
  TREADPLUS: Product.TreadPlus,
  DIGITAL: Product.DigitalApp,
  GUIDE: Product.RainforestCafe,
  ROW: Product.Row,
  REFURBBIKE: Product.RefurbBike,
  REFURBBIKEPLUS: Product.RefurbBikePlus,
};
export const fetchCtProductAvailabilityState = async (
  client: ApolloClient<NormalizedCacheObject>,
  requestedLocale?: Locale,
): Promise<AvailabilityByProduct> => {
  const baseLocale = requestedLocale || toLocaleFromHostname();
  const locale = fromLocale(baseLocale);
  const storeData = stores.find(store => store.locale === locale);

  const { data } = await client.query({
    query: GetCustomObjectByKeyDocument,
    variables: {
      key: storeData?.ctCustomObjectKey,
      container: 'product-states-schema',
    },
    fetchPolicy: 'cache-first',
  });

  const productLines = data?.customObject?.value?.productLines ?? [];
  return productLines.reduce((acc: any, { productLine, product }: any) => {
    const formattedProductLine = productLineToProduct[productLine];
    if (!formattedProductLine) {
      return { ...acc };
    }
    return {
      ...acc,
      [formattedProductLine]: product?.availability ?? UNAVAILABLE_STATE,
    };
  }, {} as AvailabilityByProduct);
};
