import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { Button1 } from '@ecomm/buttons';
import { FormattedMarkdown } from '@ecomm/formatted-markdown';
import { useQueryParams } from '@ecomm/hooks';
import { FormattedMessage } from '@ecomm/internationalize';
import { Page } from '@ecomm/layout';
import type { LinkProps } from '@ecomm/links';
import { Link } from '@ecomm/links';
import { PageSpinner } from '@ecomm/spinner';
import { b2, hr3, med } from '@ecomm/typography';
import { EmailVerificationState } from './hooks';
import { useVerifyEmail } from './hooks/useVerifyEmail';

type UgcType = 'reviews' | 'questions' | 'answers';

type UgcUrlParams = {
  ugcType: UgcType;
};

const VerifyUgcEmailPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { ugcType } = useParams<UgcUrlParams>();
  const { bv_authtoken } = useQueryParams();
  const token = getTokenFromQuery(bv_authtoken);
  const emailVerificationState = useVerifyEmail(token);

  return (
    <Page>
      <Container>
        {emailVerificationState === EmailVerificationState.LOADING ? (
          <PageSpinner />
        ) : (
          <>
            {emailVerificationState === EmailVerificationState.FAILURE ? (
              <FailureHeader>
                <FormattedMessage id="ugc.emailVerification.failureHeader" />
              </FailureHeader>
            ) : (
              <>
                <SuccessHeader>
                  <FormattedMessage id="ugc.emailVerification.successHeader" />
                </SuccessHeader>
                <SuccessBody>
                  <FormattedMarkdown id={`${ugcType}.emailVerification.successBody`} />
                </SuccessBody>
              </>
            )}
            <CTA to="/">
              <Button1>
                <FormattedMessage id="ugc.emailVerification.cta" />
              </Button1>
            </CTA>
          </>
        )}
      </Container>
    </Page>
  );
};

export default VerifyUgcEmailPage;

const getTokenFromQuery = (queryToken?: string | string[]) =>
  typeof queryToken === 'string' ? queryToken : queryToken?.[0];

const Container = styled.div`
  max-width: 960px;
  padding: 20px;
  flex-grow: 1;
  width: 100%;
  position: relative;
  text-align: center;

  ${media.tablet`
    margin: 40px auto;
  `}
`;

const SuccessHeader = styled.h3`
  ${hr3}
  margin-top: 20px;
`;

const SuccessBody = styled.div`
  ${b2}
  margin-top: 10px;
`;

const FailureHeader = styled.p`
  ${b2}
  ${med}
  margin-top: 20px;
`;

const CTA = (styled(Link)`
  display: block;
  margin-top: 60px;
` as any) as React.ComponentType<React.PropsWithChildren<LinkProps>>;
