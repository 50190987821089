import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DomainError } from '@peloton/domain-error';
import { getLocale } from '@peloton/env';
import { useErrorReporter } from '@peloton/error-reporting';
import { fetchProductState } from '@peloton/next/services/graphql/fetchProductState';
import type { AvailabilityByProduct } from '@ecomm/product-states';

const useProductLineAvailabilityStates = (): AvailabilityByProduct => {
  const baseLocale = useSelector(getLocale);
  const { errorReporter } = useErrorReporter();

  const [
    availabilityByProduct,
    setAvailabilityByProduct,
  ] = useState<AvailabilityByProduct>({} as AvailabilityByProduct);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productStates: AvailabilityByProduct = await fetchProductState(baseLocale);
        setAvailabilityByProduct(productStates);
      } catch (err) {
        const domainError = new DomainError(
          `Failed to retrieve availability states.`,
          err,
        );
        errorReporter.reportError(domainError);
        setAvailabilityByProduct({} as AvailabilityByProduct);
      }
    };

    fetchData();
  }, [baseLocale, errorReporter]);
  return availabilityByProduct;
};

export default useProductLineAvailabilityStates;
