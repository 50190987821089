import React from 'react';
import { asyncComponent } from '@peloton/code-splitting';
import { CP_MTM_SKU, STUDENT_MTM_SKU } from '@peloton/concessionary-pricing';
import { Route } from '@ecomm/router';
import { createI18nMatchSelector } from '@ecomm/saga-utils';

export const BASE_PATH = '/digital/checkout';
export const THREE_FOR_ONE_SLUG = 'digital-mtm-3for1';
export const CP_SLUGS = [CP_MTM_SKU, STUDENT_MTM_SKU];
export const DEFAULT_SLUG = 'digital-mtm';
export const FREE_TRIAL_30DAYS = 'digital-30d';
export const FREE_TRIAL_60DAYS = 'digital-60d';
export const FREE_TRIAL_60DAYS_ONE = 'pl-a-sub-01-p-60d-ft';
export const FREE_TRIAL_90DAYS = 'digital-90d';
export const FREE_TRIAL_90DAYS_PARTNERS = '90d-partnership';
export const FREE_TRIAL_90DAYS_ONE = 'pl-a-sub-01-p-90d-ft';
export const FREE_TRIAL_90DAYS_PLUS = 'pl-a-sub-02-p-90d-ft';
export const FREE_TRIAL_60DAYS_PLUS = 'pl-a-sub-02-p-60d-ft';
export const FREE_TRIAL_12M_ONE = 'pl-a-sub-01-p-12m-ft';
export const PARTNERSHIP_SLUGS = [
  FREE_TRIAL_90DAYS_ONE,
  FREE_TRIAL_90DAYS_PLUS,
  FREE_TRIAL_60DAYS_PLUS,
  FREE_TRIAL_60DAYS_ONE,
  FREE_TRIAL_12M_ONE,
];
export const PATH = `${BASE_PATH}/:slug`;
export const APP = '/app';

const Routes = [PATH, BASE_PATH].map(path => (
  <Route
    key={path}
    path={path}
    component={asyncComponent(
      () => import('./Handler' /* webpackChunkName: "DigitalCheckout" */),
    )}
  />
));

export default Routes;

export const matchDigitalCheckout = createI18nMatchSelector(PATH);
