// THIS IS AN AUTOGENERATED FILE (@ecomm/commercetools/graphql/codegen.yml). DO NOT EDIT THIS FILE DIRECTLY.
import * as Types from '../types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type GetCustomObjectByKeyQueryVariables = Types.Exact<{
  key: Types.Scalars['String'];
  container: Types.Scalars['String'];
}>;

export type GetCustomObjectByKeyQuery = { __typename?: 'Query' } & {
  customObject?: Types.Maybe<
    { __typename?: 'CustomObject' } & Pick<Types.CustomObject, 'id' | 'key' | 'value'>
  >;
};

export const GetCustomObjectByKeyDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCustomObjectByKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'container' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customObject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'container' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'container' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'key' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'key' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useGetCustomObjectByKeyQuery__
 *
 * To run a query within a React component, call `useGetCustomObjectByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomObjectByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomObjectByKeyQuery({
 *   variables: {
 *      key: // value for 'key'
 *      container: // value for 'container'
 *   },
 * });
 */
export function useGetCustomObjectByKeyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCustomObjectByKeyQuery,
    GetCustomObjectByKeyQueryVariables
  >,
) {
  return Apollo.useQuery<GetCustomObjectByKeyQuery, GetCustomObjectByKeyQueryVariables>(
    GetCustomObjectByKeyDocument,
    baseOptions,
  );
}
export function useGetCustomObjectByKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomObjectByKeyQuery,
    GetCustomObjectByKeyQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    GetCustomObjectByKeyQuery,
    GetCustomObjectByKeyQueryVariables
  >(GetCustomObjectByKeyDocument, baseOptions);
}
export type GetCustomObjectByKeyQueryHookResult = ReturnType<
  typeof useGetCustomObjectByKeyQuery
>;
export type GetCustomObjectByKeyLazyQueryHookResult = ReturnType<
  typeof useGetCustomObjectByKeyLazyQuery
>;
export type GetCustomObjectByKeyQueryResult = Apollo.QueryResult<
  GetCustomObjectByKeyQuery,
  GetCustomObjectByKeyQueryVariables
>;
