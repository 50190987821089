import type { NormalizedCacheObject, ApolloLink } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import React, { useEffect } from 'react';
import { useOauth } from '@peloton/auth';
import { getJwtFromCookies } from '@peloton/auth/authToken';
import { CHECKOUT_ACCESS_TOKEN_STORAGE_KEY } from '@peloton/auth/constants';
import type { ExtLinkEnv } from '@peloton/external-links';
import { toApolloLinkHeaders } from '@peloton/graphql/toClient';
import type {
  CartQuery,
  CartQueryVariables,
} from '@ecomm/cart-next/graphql/queries/Cart.generated';
import { CartDocument } from '@ecomm/cart-next/graphql/queries/Cart.generated';
import type { Toggles } from '@ecomm/feature-toggle/models/Toggles';
import useLocalStorage from '@ecomm/hooks/useLocalStorage';
import { getApolloClientInstanceV3 } from './clientInstance';

export const getHeadersWithToken = (headers: object, token?: string | null) => {
  if (!token) {
    return headers;
  }
  const authToken = token.replace(/"/g, '');

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${authToken}`,
    },
  };
};

const Provider: React.FC<
  React.PropsWithChildren<{
    env: ExtLinkEnv;
    toggles: Toggles<string>;
    localeProductStates: NormalizedCacheObject;
    locale?: string;
  }>
> = ({ env, toggles, localeProductStates, locale, ...props }) => {
  const { isAuthenticated, getAccessTokenSilently } = useOauth();
  const [checkoutAccessToken] = useLocalStorage(CHECKOUT_ACCESS_TOKEN_STORAGE_KEY, null);
  let checkoutToken = checkoutAccessToken as string | null;
  if (typeof checkoutToken === 'string') {
    // remove potential double quotes from token being stringified
    checkoutToken = checkoutToken.replace(/"/g, '');
  }
  const isSecretAuthHeaderEnable = toggles['authorizationToken'].active;

  const isOauthCookiesEnabled = toggles['oauth_cookie_enabled'].active;

  const jwt = isOauthCookiesEnabled ? getJwtFromCookies() : checkoutToken;

  const authLink = setContext(async (_, { headers }) => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      return {
        ...getHeadersWithToken(headers, token),
        ...toApolloLinkHeaders(isSecretAuthHeaderEnable),
      };
    } else {
      return {
        ...getHeadersWithToken(headers, jwt),
        ...toApolloLinkHeaders(isSecretAuthHeaderEnable),
      };
    }
  }) as ApolloLink;

  const options = {
    initialState: localeProductStates,
    authLink,
    name: 'www/ecomm-graphql',
  };
  // @ts-expect-error
  const apolloClientV3 = getApolloClientInstanceV3(locale || 'en-US', options);

  useEffect(() => {
    apolloClientV3.query<CartQuery, CartQueryVariables>({
      query: CartDocument,
      variables: { calculateEstimatedShippingPrice: toggles['projectPhoenix'].active },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ApolloProvider client={apolloClientV3}>{props.children}</ApolloProvider>;
};

export default Provider;
