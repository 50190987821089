import { always, cond } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { call, select, takeEvery } from 'redux-saga/effects';
import { track } from '@peloton/analytics';
import type { Location } from '@peloton/redux';
import { getLocation } from '@peloton/redux';
import { trackCustomEvent } from '@peloton/split-testing';
import { getUserTrackingProperties } from '@ecomm/auth';
import type { Cart } from '@ecomm/cart/models';
import { selectCart } from '@ecomm/cart/redux';
import type { ID, Slug } from '@ecomm/models';
import { toDollars } from '@ecomm/models';
import {
  isBikeBundle,
  isBikePlusBundle,
  isTreadBundle,
  isTreadPlusBundle,
  isGuideBundle,
  ProductLine,
  toEquipmentTypeFromBundleType,
} from '../models';
import type { Bundle } from '../models/Bundle';
import type { Product } from '../models/Product';
import type { ProductOption } from '../models/ProductOption';

import toTrackingCategory from '../models/toTrackingCategory';
import {
  areProductsProductLine,
  getBundleById,
  getProductById,
  getProductOptionById,
  getProductOptionsByProductLine,
} from '../redux';
import type { AddToCartSuccessAction } from '../redux/shopper';
import { ActionType } from '../redux/shopper';

export const getDevice = function* (bundle: Bundle): SagaIterator {
  const productLine = cond([
    [isBikeBundle, always(ProductLine.Bike)],
    [isBikePlusBundle, always(ProductLine.BikePlus)],
    [isTreadBundle, always(ProductLine.Tread)],
    [isTreadPlusBundle, always(ProductLine.TreadPlus)],
    [isGuideBundle, always(ProductLine.RainforestCafe)],
  ])(bundle);

  const devices = yield select(getProductOptionsByProductLine, {
    productLine,
  });

  if (devices.length === 0) return;
  return devices[0];
};

// Disabling fall through to avoid duplication.
const toDeviceName = (productLine: ProductLine) => {
  switch (productLine) {
    case ProductLine.Bike:
      return 'Bike';
    case ProductLine.Tread:
    case ProductLine.TreadPlus:
      return 'Tread';
    case ProductLine.StoneOcean:
    case ProductLine.BikePlus:
      return 'Bike-plus';
    case ProductLine.RainforestCafe:
      return 'Guide';
    default:
      return 'Other';
  }
};

type BaseProductProperties = {
  cartId: string;
  propertyType: string;
  quantity: number;
  hasTradeIn: boolean;
  page: string;
};

const toTrackProduct = (
  properties: BaseProductProperties & {
    id: ID;
    name: string;
    slug: Slug;
    price: number;
    sku: string;
    category: string;
    isUpsell?: boolean;
  },
) => ({
  event: 'Added Product',
  properties,
});

export const addAccessoryAnalyticsSaga = function* (
  properties: BaseProductProperties,
  productId: ID,
  productOptionId: ID = '',
  isUpsell: boolean = false,
) {
  const product: Product = yield select(getProductById, { id: productId }) || {};
  const productOption: ProductOption = yield select(getProductOptionById, {
    id: productOptionId,
  }) || {};
  if (!product || !productOption) {
    return;
  }

  yield call(
    track,
    toTrackProduct({
      ...properties,
      id: product.id,
      name: productOption.name,
      slug: product.slug,
      price: toDollars(product.price),
      sku: productOption.sku,
      category: 'accessory',
      isUpsell,
    }),
  );
};

export const addDeviceAnalyticsSaga = function* (
  action: AddToCartSuccessAction,
): SagaIterator {
  const { id, optionId, isUpsell } = action.payload;
  const user: any = yield select(getUserTrackingProperties);
  const { cart }: { cart: Cart } = yield select(selectCart);
  const { pathname }: Location = yield select(getLocation);
  const bundle: Bundle = yield select(getBundleById, { id }) || {};

  const baseProductProperties: BaseProductProperties = {
    cartId: cart.id,
    propertyType: 'Web',
    quantity: 1,
    hasTradeIn: Boolean(cart.tradeIn),
    graphql: false,
    page: pathname,
    ...user,
    isUpsell,
  };

  if (!bundle) {
    yield call(addAccessoryAnalyticsSaga, baseProductProperties, id, optionId, isUpsell);
    return;
  }

  const device = yield call(getDevice, bundle);

  if (device) {
    const deviceName = toDeviceName(device.productLine);

    yield call(track, {
      event: `Added ${deviceName}`,
      properties: {
        ...baseProductProperties,
        id: deviceName,
        name: deviceName,
        sku: device.sku,
        price: toDollars(device.price),
      },
    });

    yield call(
      track,
      toTrackProduct({
        ...baseProductProperties,
        id: deviceName,
        name: bundle.name,
        slug: bundle.slug,
        sku: device.sku,
        category: toTrackingCategory(toEquipmentTypeFromBundleType(bundle.bundleType)),
        price: toDollars(device.price),
      }),
    );
  }

  const hasAccessories = yield select(areProductsProductLine, {
    products: bundle.products,
    productLine: ProductLine.Other,
  });

  if (hasAccessories) {
    yield call(track, {
      event: 'Added Bundle',
      properties: {
        cartId: cart.id,
        id,
        graphql: false,
        name: bundle.name,
        price: toDollars(bundle.price),
        propertyType: 'Web',
      },
    });
  }
};

export const trackAddToCartSaga = function* (action: AddToCartSuccessAction) {
  const { cart } = yield select(selectCart);

  yield call(trackCustomEvent, 'addToCart', {
    cartId: cart && cart.id,
  });
};

const sagas = function* () {
  yield takeEvery(ActionType.AddToCartSuccess, addDeviceAnalyticsSaga);
  yield takeEvery(ActionType.AddToCartSuccess, trackAddToCartSaga);
};

export default sagas;
