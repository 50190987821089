import { identity } from 'ramda';
import type { Client } from '@peloton/api';
import { pipeDataTwice, pipeError, throwError } from '@peloton/api';
import type { ErrorCode } from './models';
import { TWO_WEEKS, toErrorCode } from './models';
import type { Days } from './models/Days';

const toTrialUrl = () => '/api/subscription/digital/trial';

export const grantFreeTrial = (api: Client, duration: Days = TWO_WEEKS) =>
  api
    .post(toTrialUrl(), { days: duration })
    .then(pipeDataTwice(identity))
    .catch(pipeError(throwError<ErrorCode>(toErrorCode)));
