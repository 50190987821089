import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { generateIdMeAuthorizationLink } from '@peloton/concessionary-pricing';
import { useExtLinkEnv } from '@peloton/external-links/context/Provider';
import { media, hoverTransition } from '@peloton/styles';
import { useCtaWithUrlData } from '@ecomm/copy';
import logAction from '@ecomm/logging/logAction';
import IdMeSvg from './IdMeSvg';

export const IdMeButton = () => {
  const externalLinks = useExtLinkEnv();
  const { trackEvent } = useTracking();

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    trackEvent({
      event: 'Clicked Hero CTA on Offers',
      properties: {
        linkTo: generateIdMeAuthorizationLink(externalLinks),
        parent: 'Hero on Offers',
        unitName: 'ID.me CTA',
      },
    });
    logAction('ID.me Verification - Clicked ID.me Button');
    location.href = generateIdMeAuthorizationLink(externalLinks);
  };

  return (
    <StyledButton
      type="button"
      id="idme-wallet-button"
      data-test-id="idme-wallet-button"
      onClick={handleClick}
    >
      Verify with
      <IdMeSvg />
    </StyledButton>
  );
};

export const IdMeHostLandingPageButton = () => {
  const { url } = useCtaWithUrlData('cp.landing.checkEligibility');
  const { trackEvent } = useTracking();

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    trackEvent({
      event: 'Clicked Link',
      properties: {
        linkTo: url,
        parent: 'Offer Entry Point',
        unitName: 'ID.me Offer CTA',
      },
    });
    logAction('ID.me Verification - Clicked ID.me CFU Offer Button');
    location.href = url;
  };

  return (
    <StyledButton
      type="button"
      id="idme-wallet-button"
      data-test-id="idme-cfu-offer-button"
      onClick={handleClick}
      style={{ minWidth: '216px', color: 'white' }}
    >
      Verify with
      <IdMeSvg />
    </StyledButton>
  );
};

export const IDME_BUTTON_COLOR_MAP = {
  DEFAULT: '#08833D',
  HOVER: '#30975C',
  ACTIVE: '#06602D',
};

const StyledButton = styled.button`
  height: 40px;
  background-color: ${IDME_BUTTON_COLOR_MAP.DEFAULT};
  width: 100%;
  border-radius: 4px;
  border: 3px solid transparent;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;

  ${media.tabletLarge`
    width: 220px;  
    height: 48px;
  `};

  ${hoverTransition({
    property: 'background-color',
    to: IDME_BUTTON_COLOR_MAP.HOVER,
  })}
  &:active {
    background-color: ${IDME_BUTTON_COLOR_MAP.ACTIVE};
  }
  &:focus {
    background-color: ${IDME_BUTTON_COLOR_MAP.HOVER};
    border: 3px solid ${IDME_BUTTON_COLOR_MAP.DEFAULT};
  }
`;
