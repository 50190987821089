import { schema } from 'normalizr';
import { assoc, isNil, pipe, propSatisfies, when } from 'ramda';
import { BillingType } from '../../models/BillingType';
import type { Subscription } from '../../models/Subscription';
import { SubscriptionKind } from '../../models/SubscriptionKind';
import type { ApiSubscription } from '../types';
import type { KindlessSub } from './toSubscription';
import toSubscription from './toSubscription';

const setDevice = assoc('kind', SubscriptionKind.Device);
const setInternalBilling = assoc('billingType', BillingType.PelotonInternal);

const toDeviceSubscription = pipe<
  ApiSubscription,
  KindlessSub,
  KindlessSub,
  Subscription
>(
  toSubscription,
  // Device subs don't have a billingType, so we force it
  when(propSatisfies(isNil, 'billingType'), setInternalBilling),
  setDevice,
);

const deviceSubscription = new schema.Entity(
  'subscriptions',
  {},
  { processStrategy: toDeviceSubscription },
);

export default deviceSubscription;
