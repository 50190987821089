import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import type { Locale } from '@peloton/internationalize';
import { fetchCtProductAvailabilityState } from '@peloton/next/services/commercetools/fetchCtProductAvailabilityState';
import { toCommercetoolsClient } from '@ecomm/commercetools/apollo/toCommercetoolsClient';

export const fetchProductState = async (locale: Locale) => {
  const client = toCommercetoolsClient();

  try {
    const cacheData = require(`@ecomm/product-states/static/${locale}.ctProductStateApolloCache.json`);
    client.cache.restore(cacheData);
  } catch (err) {
    console.log(`Failed to load cache from ${locale}.ctProductStateApolloCache`);
  }

  return fetchCtProductAvailabilityState(
    client as ApolloClient<NormalizedCacheObject>,
    locale,
  );
};
