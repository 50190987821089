import {
  brand,
  Eyebrow,
  white,
  spacing,
  Flex,
  FlexChild,
  Container as DSContainer,
  Support,
  BreakpointWidths,
} from '@pelotoncycle/design-system';
import { transparentize } from 'polished';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { track } from '@peloton/analytics';
import { toCountry } from '@peloton/internationalize';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';
import { useSubHeaderReference } from '@acme-ui/global';
import { trackWithTestIds } from '@ecomm/analytics';
import { TrackingEvent } from '@ecomm/analytics/models';
import { getBasePriceForBundleType } from '@ecomm/config/redux';
import type { Headband as HeadbandKey } from '@ecomm/copy';
import { useHeadbandData } from '@ecomm/copy';
import { useFinancingValues } from '@ecomm/copy/helpers/useFinancingValues';
import { useProjectPhoenixCopyKey } from '@ecomm/feature-toggle/projectPhoenix';
import useHasFinancingPartner from '@ecomm/financing/hooks/useHasFinancingPartner';
import { FormattedMessage } from '@ecomm/internationalize';
import { LinkButton } from '@ecomm/internationalize-ui';
import { toDollarsCeil } from '@ecomm/models';
import {
  Product,
  ProductStates,
  useProductStates,
  useToLinkIfShopCta,
} from '@ecomm/product-states';
import { b5 } from '@ecomm/typography';
import Markdown from '@page-builder/utils/Markdown';
import {
  HEADBAND_HEIGHT_MOBILE,
  HEADBAND_HEIGHT_TABLET_XLARGE,
  zIndex,
} from '../constants';
import useHeadbandConfig from '../hooks/useHeadbandConfig';
import FinancingInfoMarkdown from './FinancingInfoMarkdown';

const { getSubnavCtaTestId } = trackWithTestIds;

export type Props = React.HTMLAttributes<HTMLDivElement> & {
  cmsId: HeadbandKey;
  style?: React.CSSProperties;
  disclaimerTextId?: string;
  product: string;
  promotion?: string;
  ctaOverride?: React.ReactNode;
  productInProjectPhoenix?: boolean;
};

// TODO: Slug here needs to be typed somehow
export const slugToProduct = (slug: string): Product | null => {
  switch (slug) {
    case 'bikes':
      return Product.Bike;
    case 'bike':
      return Product.Bike;
    case 'bike-plus':
      return Product.BikePlus;
    case 'tread':
      return Product.Tread;
    case 'tread-plus':
      return Product.TreadPlus;
    case 'treads':
      return Product.Tread;
    case 'app':
      return Product.DigitalApp;
    case 'guide':
      return Product.RainforestCafe;
  }

  return null;
};

const Headband: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  cmsId,
  product,
  promotion = undefined,
  ctaOverride,
  productInProjectPhoenix = false,
  disclaimerTextId,
}) => {
  const headbandRef = useSubHeaderReference();
  const { headingTestId, subHeadingTestId } = useHeadbandConfig();

  const projectPhoenixCopyKey = useProjectPhoenixCopyKey(cmsId);
  const headbandCopyKey = productInProjectPhoenix ? projectPhoenixCopyKey : cmsId;

  const { financingText, device, title, ctaLink } = useHeadbandData(headbandCopyKey);

  const total = useSelector(getBasePriceForBundleType(device));

  const { verifyProductState } = useProductStates();

  const prodType = slugToProduct(product);
  const invalidStates = [ProductStates.Unavailable, ProductStates.UnavailableMarketing];

  const shouldShow = prodType === null || !verifyProductState(prodType, invalidStates);

  const cta = useToLinkIfShopCta(ctaLink.url);

  const hasFinancingPartner = useHasFinancingPartner();

  const pricing = useFinancingValues();

  return shouldShow ? (
    <Container
      className={className}
      data-element-id="subheader"
      ref={headbandRef}
      justifyContent="space-between"
      alignItems="center"
      padding={{
        mobile: `0 ${spacing[16]}`,
        tablet: `0 ${spacing[24]}`,
        desktop: `0 ${spacing[32]}`,
      }}
      position="sticky"
    >
      <Eyebrow textColor={white}>{title}</Eyebrow>
      <Flex alignItems="center">
        {financingText && (
          <DSContainer
            minWidth="196px"
            maxWidth="262px"
            padding="0 15px 0 0"
            textAlign="right"
            style={{ flexDirection: 'column', alignItems: 'flex-end' }}
            display={{ mobile: 'none', desktop: 'flex' }}
          >
            {financingText && (
              <Support
                is="p"
                size="medium"
                textColor={white}
                data-test-id={headingTestId}
              >
                {hasFinancingPartner ? (
                  <FinancingInfoMarkdown device={device} financingText={financingText} />
                ) : (
                  // what regions dont have financing partners?
                  <Markdown
                    content={financingText}
                    values={{
                      total: toDollarsCeil(total),
                    }}
                  />
                )}
              </Support>
            )}
            {disclaimerTextId ? (
              <Support
                is="p"
                size="medium"
                textColor={white}
                data-test-id={subHeadingTestId}
                style={{ whiteSpace: 'nowrap' }}
              >
                <FormattedMessage
                  id={disclaimerTextId}
                  values={{
                    ...pricing,
                    device,
                  }}
                />
              </Support>
            ) : (
              <Support
                is="p"
                size="medium"
                textColor={white}
                data-test-id={subHeadingTestId}
                style={{ whiteSpace: 'nowrap' }}
              >
                <FormattedMessage
                  id="subnavFinancing.disclaimer"
                  values={{
                    ...pricing,
                    device,
                  }}
                />
              </Support>
            )}
          </DSContainer>
        )}
        {cta &&
          (ctaOverride ? (
            ctaOverride
          ) : (
            <FlexChild flexBasis="120px" flexShrink={0}>
              <LinkButton
                href={cta}
                size="small"
                width="adaptive"
                style={{ paddingInline: 60 }}
                margin={`${spacing[8]} 0;`}
                data-test-id={getSubnavCtaTestId('ctaLink')}
                onClick={() =>
                  track({
                    event: TrackingEvent.ClickedSubnavATC,
                    properties: {
                      page: getCurrentRelativePathname(),
                      linkTo: cta,
                      productName: device,
                      promotion,
                      country: toCountry(),
                    },
                  })
                }
              >
                <span data-test-id={getSubnavCtaTestId('ctaCopy')}>{ctaLink.text}</span>
              </LinkButton>
            </FlexChild>
          ))}
      </Flex>
    </Container>
  ) : null;
};

export default Headband;

const Container = styled(Flex)`
  height: ${HEADBAND_HEIGHT_MOBILE}px;
  background-color: ${transparentize(0.15, brand.dark)};
  top: 0;
  z-index: ${zIndex.HEADBAND};
  width: 100%;
  overflow: hidden;
  margin-bottom: -${HEADBAND_HEIGHT_MOBILE}px;
  color: ${white};

  h2 + span {
    width: auto;
    min-width: 160px;
    max-height: 32px;
  }

  @media (min-width: ${BreakpointWidths.tablet}px) {
    height: ${HEADBAND_HEIGHT_TABLET_XLARGE}px;
    margin-bottom: -${HEADBAND_HEIGHT_TABLET_XLARGE}px;
    h2 + span {
      max-height: none;
    }
  }
`;

export const FinancingInfo = styled.p`
  ${b5};
  color: white;
  font-weight: 400;
  em {
    font-style: normal;
    font-size: 10px;
    display: inline-block;
    transform: translateY(-5px);
  }
`;
