import { path, prop } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { all, call, getContext, put, takeEvery, select } from 'redux-saga/effects';
import { CLIENT_CONTEXT } from '@peloton/api';
import { trackDrift } from '@peloton/drift';
import { updateShipping } from '@ecomm/checkout/redux';
import type { ContactInfo } from '@ecomm/models';
import { removeItemFromCart } from '../api/removeItemFromCart';
import { getCartItems, getCartId, loadCartSuccess } from '../redux';
import type { RemoveItemAction } from '../redux/removeItem';
import { ActionType, removeItemSucceeded, removeItemFailed } from '../redux/removeItem';

// TODO: error/loading handling
export const removeItemSaga = function* ({
  id,
  itemType,
  quantity,
}: RemoveItemAction): SagaIterator {
  try {
    const currentItems = yield select(getCartItems);
    const cartId = yield select(getCartId);
    const itemToRemove = currentItems.find(
      (item: any) => item.id === id && item.type === itemType,
    );

    const client = yield getContext(CLIENT_CONTEXT);
    const cart = yield call(removeItemFromCart, client, id, itemType, quantity);
    yield all([put(loadCartSuccess(cart)), put(removeItemSucceeded())]);

    const maybePostalCode = path(['shipping', 'address', 'postalCode'], cart);
    if (maybePostalCode) {
      yield put(updateShipping(prop('shipping', cart) as ContactInfo));
    }

    yield call(trackDrift, {
      event: `Removed ${itemToRemove.name} from cart`,
      properties: {
        cartId,
        quantity,
      },
    });
  } catch (e) {
    // TODO: handle remove item errors
    yield put(removeItemFailed('cart.errors.removeItemFailed'));
  }
};

export const removeItem = function* () {
  yield takeEvery(ActionType.RemoveItem, removeItemSaga);
};

export default removeItem;
