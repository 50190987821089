import { Status } from '@ecomm/models';
import { Partners } from './models/Partners';
import type { State } from './redux';

export const isKlarnaLoaded = (state: State) => state.isKlarnaLoaded;

export const isSubmitting = (state: State) => state.isSubmitting;

export const isKlarnaLoading = (state: State) => state.isKlarnaLoaded === Status.Loading;

export const isKlarnaSDKLoaded = (state: State) =>
  state.klarnaSDKStatus === Status.Loaded;

// TODO: put where combineReducers is called?
export type FinancingState = {
  financing: State;
};

export const usesKlarnaIntegration = (partner: Partners) =>
  partner === Partners.Klarna || partner === Partners.PayBright;

export const getFinancing = (state: FinancingState) => state.financing;

export const isFinancingAvailable = (state: FinancingState) =>
  getFinancing(state).isKlarnaPaymentAvailable;

export const isKlarnaWidgetOpen = (state: FinancingState) =>
  getFinancing(state).isKlarnaWidgetOpen;

export const isLegalAccepted = (state: FinancingState) =>
  getFinancing(state).isLegalAccepted;

export const isLegalModalOpen = (state: FinancingState) =>
  getFinancing(state).isLegalModalOpen;
