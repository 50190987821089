import { pipe, split, last } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import { call, delay, getContext, put, select, takeLatest } from 'redux-saga/effects';
import type * as yup from 'yup';
import { CLIENT_CONTEXT } from '@peloton/api';
import { DomainError } from '@peloton/domain-error';
import { startChat } from '@peloton/drift';
import { getLocale } from '@peloton/env';
import { reportError } from '@peloton/error-reporting';
import {
  DRIFT_CANADA_UNDELIVERABLE_INTERACTION_ID,
  theUserIsInCanada,
} from '@peloton/internationalize';
import { updateShipping } from '@ecomm/cart/api';
import { ShippingErrorCode } from '@ecomm/cart/models';
import { loadCartInitialSuccess } from '@ecomm/cart/redux';
import { openModal } from '@ecomm/trade-in/redux';
import type { Validator } from '../models';
import { AddressType, toAddressValidation, toContactInfoFromAddress } from '../models';
import type { Address } from '../models/Address';
import { ActionTypes as AddressActions } from '../redux/address';
import { getCheckoutAddress } from '../redux/selectors';
import type { UpdateFailure } from '../redux/shipping';
import {
  Actions,
  updateShippingFailure,
  updateShippingInvalid,
  updateShippingSuccess,
} from '../redux/shipping';

export const handleFailureSaga = function* ({
  payload: { id },
}: UpdateFailure): SagaIterator {
  const locale = yield select(getLocale);

  try {
    if (theUserIsInCanada(locale)) {
      if (yield call(isPostalCodeUnservicedError, id)) {
        yield put(startChat(DRIFT_CANADA_UNDELIVERABLE_INTERACTION_ID));
      }
    }
  } catch (e) {
    const error = new DomainError(`Error starting interaction #${id} with drift`, e);
    yield put(reportError({ error }));
  }

  if (yield call(isTradeInUnavailableError, id)) {
    yield put(openModal());
  }
};

export const validateSaga = function* (): SagaIterator {
  yield delay(300);
  const locale = yield select(getLocale);

  // We don't need to validate country since its hardcoded (not user input)
  const { country, ...validations } = toAddressValidation(locale);

  const address = yield select(getCheckoutAddress, {
    addressType: AddressType.Shipping,
  });

  try {
    yield call(validateAddressFields, validations, address);
    yield call(updateShippingSaga);
  } catch (_) {
    yield put(updateShippingInvalid());
  }
};

// see https://github.com/redux-saga/redux-saga/issues/1177 for typing 'any'
export const validateAddressFields: any = function (
  validations: Validator,
  address: Address,
) {
  return new Promise((resolve, reject) => {
    const validationPromises = Object.entries(validations).map(([field, validator]) => {
      return validator.validate(address[field]).catch((error: yup.ValidationError) => {
        reject({ error });
      });
    });

    Promise.all(validationPromises).then(value => {
      resolve({ value });
    });
  });
};

export const updateShippingSaga = function* (): SagaIterator {
  try {
    const client = yield getContext(CLIENT_CONTEXT);
    const address = yield select(getCheckoutAddress, {
      addressType: AddressType.Shipping,
    });

    const cart = yield call(updateShipping, client, toContactInfoFromAddress(address));
    yield put(loadCartInitialSuccess(cart));
    yield put(updateShippingSuccess());
  } catch (e) {
    yield put(updateShippingFailure(e));
  }
};

export const shippingSagas = function* () {
  yield takeLatest(
    [Actions.UpdateShippingRequest, AddressActions.AddressFieldBlurred],
    validateSaga,
  );
  yield takeLatest(Actions.UpdateFailure, handleFailureSaga);
};

export default shippingSagas;

export const isPostalCodeUnservicedError = pipe(split('.'), last, (x: string) =>
  [
    ShippingErrorCode.PostalCodeUnserviced,
    ShippingErrorCode.PostalCodeDoesNotExist,
  ].includes(x as any),
);

export const isTradeInUnavailableError = pipe(split('.'), last, (x: string) =>
  [ShippingErrorCode.TradeInUnavailable].includes(x as any),
);
