import { BillingFrequency } from '@membership/models/Subscription';
import { always, cond, equals } from 'ramda';
import type { Omit } from '@peloton/types';
import { BillingType } from '../../models/BillingType';
import { Platform } from '../../models/Platform';
import { Status } from '../../models/Status';
import type { Subscription } from '../../models/Subscription';
import { SubscriptionType } from '../../models/SubscriptionType';
import type { ApiSubscription } from '../types';

export type KindlessSub = Omit<Subscription, 'kind'> & {
  billingType: BillingType | undefined;
};

const toSubscription = (sub: ApiSubscription): KindlessSub => ({
  createdAt: sub.createdAt,
  id: sub.id,
  costInCents: sub.costInCents,
  billingFrequency: toBillingFrequency(sub.billingFrequency),
  billingType: toBillingType(sub.billingType),
  hasEquipmentLease: sub.hasEquipmentLease,
  name: sub.name,
  platform: toPlatform(sub.platform),
  subscriptionType: toSubscriptionType(sub.subscriptionType),
  status: toStatus(sub.status),
  tierType: sub.tierType,
});

export default toSubscription;

const toBillingType = (billingType?: string): BillingType =>
  cond([
    [equals('amazon_monthly'), always(BillingType.AmazonMonthly)],
    [equals('apple_monthly'), always(BillingType.AppleMonthly)],
    [equals('apple_one_week'), always(BillingType.AppleOneWeek)],
    [equals('google_monthly'), always(BillingType.GoogleMonthly)],
    [equals('peloton_internal'), always(BillingType.PelotonInternal)],
    [equals('pre_delivery'), always(BillingType.PreDelivery)],
    [equals('trial'), always(BillingType.Trial)],
  ])(billingType);

const toPlatform = (platform?: string): Platform =>
  cond([
    [equals('apple'), always(Platform.Apple)],
    [equals('amazon'), always(Platform.Amazon)],
    [equals('bango'), always(Platform.Bango)],
    [equals('google'), always(Platform.Google)],
    [equals('peloton'), always(Platform.Peloton)],
    [equals('roku'), always(Platform.Roku)],
  ])(platform);

const toStatus = (status: string): Status =>
  cond([
    [equals('active_auto_renew_off'), always(Status.ActiveAutoRenewOff)],
    [equals('active_scheduled_for_cancellation'), always(Status.ActiveCancelScheduled)],
    [equals('active_in_grace_period'), always(Status.ActiveGracePeriod)],
    [equals('active_normal'), always(Status.ActiveNormal)],
    [equals('cancelled'), always(Status.Cancelled)],
    [equals('expired'), always(Status.Expired)],
    [equals('inactive'), always(Status.Inactive)],
    [equals('overdue_delinquent'), always(Status.OverdueDelinquent)],
    [equals('overdue_no_payment'), always(Status.OverdueNoPayment)],
    [equals('paused'), always(Status.Paused)],
    [equals('unused'), always(Status.Unused)],
  ])(status);

const toSubscriptionType = (subscriptionType: string): SubscriptionType =>
  cond([
    [equals('commercial'), always(SubscriptionType.Commercial)],
    [equals('consumer'), always(SubscriptionType.Consumer)],
    [equals('guide'), always(SubscriptionType.Guide)],
  ])(subscriptionType);

const toBillingFrequency = (billingFrequency?: string | null): BillingFrequency =>
  cond([
    [equals('monthly'), always(BillingFrequency.Monthly)],
    [equals('annual'), always(BillingFrequency.Annual)],
  ])(billingFrequency);
