import { Store } from '@peloton/stores/WebStore';
import { Currency, Locale } from '@ecomm/graphql/types.generated';
import type { CatalogQueryVars } from '@ecomm/pricing';

export const stores: CatalogQueryVars[] = [
  {
    locale: Locale.DeAt,
    currency: Currency.Eur,
    catalog: Store.Austria,
    ctCustomObjectKey: 'product-states-de-at',
  } as CatalogQueryVars,
  {
    locale: Locale.DeDe,
    currency: Currency.Eur,
    catalog: Store.Germany,
    ctCustomObjectKey: 'product-states-de-de',
  } as CatalogQueryVars,
  {
    locale: Locale.EnAu,
    currency: Currency.Aud,
    catalog: Store.Australia,
    ctCustomObjectKey: 'product-states-en-au',
  } as CatalogQueryVars,
  {
    locale: Locale.EnCa,
    currency: Currency.Cad,
    catalog: Store.Canada,
    ctCustomObjectKey: 'product-states-en-ca',
  } as CatalogQueryVars,
  {
    locale: Locale.EnGb,
    currency: Currency.Gbp,
    catalog: Store.UnitedKingdom,
    ctCustomObjectKey: 'product-states-en-gb',
  } as CatalogQueryVars,
  {
    locale: Locale.EnUs,
    currency: Currency.Usd,
    catalog: Store.UnitedStates,
    ctCustomObjectKey: 'product-states-en-us',
  } as CatalogQueryVars,
];
