import type { NormalizedCacheObject, ApolloClient } from '@apollo/client';
import type { SagaIterator } from 'redux-saga';
import { all, call, select } from 'redux-saga/effects';
import { track } from '@peloton/analytics';
import { shouldTrackConcessionary } from '@peloton/concessionary-pricing';
import { toCountry, toLocaleFromHostname } from '@peloton/internationalize';
import { toLocaleFromTLD } from '@peloton/internationalize/models/locale';
import { getUserTrackingProperties } from '@ecomm/auth';
import type { Item } from '@ecomm/cart';
import {
  hasOrIsAccessory,
  hasOrIsBike,
  hasOrIsBikePlus,
  hasOrIsGuide,
  hasOrIsRefurbishedBike,
  hasOrIsRefurbishedBikePlus,
  hasOrIsRow,
  hasOrIsTread,
  hasOrIsTreadPlus,
  getCartId,
  getCartItems,
  getCartViewedProps,
  getCheckoutCategory,
  getHasTradeIn,
  getPromotionId,
  getShippingMethod,
  hasOrIsRefurbishedBikeOrBikePlus,
  toPrimaryImage,
} from '@ecomm/cart';
import { isPrepaidCreditItem } from '@ecomm/cart-next/cart-summary/cart-items/models';
import type { CartQuery } from '@ecomm/cart-next/graphql/queries/Cart.generated';
import { CartDocument } from '@ecomm/cart-next/graphql/queries/Cart.generated';
import {
  hasGuideBundle,
  toAccessoriesProps,
  toBundleName,
} from '@ecomm/cart/models/analytics';
import {
  getSelectedPaymentMethod,
  getSelectedFinancingPartner,
  getVerification,
} from '@ecomm/checkout/redux';
import { toCurrencyfromLocale } from '@ecomm/graphql-bridge';
import { getApolloClientInstanceV3 } from '@ecomm/graphql/clientInstance';
import { getActiveRoute } from '@ecomm/router';

export type Payload = {
  category: string;
  checkoutID: string;
  email?: string;
  hasTradeIn: boolean;
  paymentMethod: string;
  shippingMethod: string;
  country: string;
  page: string;
  promotion?: string;
  verificationId?: string;
  hasGift?: boolean;
};

export type OrderItem = {
  name: string;
  price: string;
  price_in_cents: number;
  description: string;
  category: string;
  image: string;
  bundle_products?: BundleProduct[];
};

type BundleProduct = {
  name: string;
  sku: string;
  image: string;
};

export type AdditionalTrackingProps = {
  errorState?: string;
  skus?: string;
};

export const toTrackCheckoutStepsSaga = (toEvent: (s: number, p: Payload) => any) =>
  function* (
    step: number,
    additionalTrackingProps: AdditionalTrackingProps = {},
  ): SagaIterator {
    const cart = yield call(getCartInformation);
    const activeRoute = yield select(getActiveRoute);
    const category = yield select(getCheckoutCategory);
    const cartItems = yield select(getCartItems);
    let promotion = yield select(getPromotionId);
    let verificationId = '';
    if (category === 'digital') {
      const verification = yield select(getVerification);
      if (
        shouldTrackConcessionary(
          activeRoute,
          promotion,
          verification,
          additionalTrackingProps.skus,
        )
      ) {
        promotion = verification.segment;
        verificationId = verification.id;
      }
    }

    const user = yield select(getUserTrackingProperties);
    const { accessories, hasAccessory } = toAccessoriesProps(cartItems);

    const numberOfCredits =
      (cart?.items ?? []).find(isPrepaidCreditItem)?.numberOfCredits ?? 0;

    const payload: Payload = yield all({
      category,
      checkoutID: select(getCartId),
      errorState: additionalTrackingProps.errorState || '',
      hasTradeIn: select(getHasTradeIn),
      products: select(getCartViewedProps),
      paymentMethod: select(getSelectedPaymentMethod),
      billingPartner: select(getSelectedFinancingPartner),
      shippingMethod: select(getShippingMethod),
      country: toCountry(),
      page: activeRoute,
      promotion,
      skus: additionalTrackingProps.skus,
      verificationId,
      ...user,
      bundleName: toBundleName(cartItems),
      hasCPO: cartItems.filter(hasOrIsRefurbishedBikeOrBikePlus).length > 0,
      accessories,
      hasAccessory,
      ...hasGuideBundle(toBundleName(cartItems)),
      hasGift: cart?.isGift,
      orderItems: getOrderItems(cartItems),
      hasSubscriptionGift: cart?.hasPrepaidCredit,
      giftSubscriptionDuration: numberOfCredits ? `${numberOfCredits} months` : null,
    });

    yield call(track, toEvent(step, payload));
  };

export const getCartInformation = async () => {
  const locale = toLocaleFromHostname(window.location.hostname);
  // @ts-expect-error
  const apolloClient: ApolloClient<NormalizedCacheObject> = getApolloClientInstanceV3(
    locale,
  );

  if (!apolloClient) {
    return;
  }

  const { data } = await apolloClient.query<CartQuery>({
    query: CartDocument,
    variables: { calculateEstimatedShippingPrice: false },
  });

  return data?.cart;
};

export const getOrderItems = (items: Item[]): OrderItem[] => {
  const orderItems: OrderItem[] = [];

  const locale = toLocaleFromTLD();
  const currency = toCurrencyfromLocale(locale);

  items.forEach(item => {
    const orderItem: OrderItem = {
      name: item.name,
      price: toFormattedPrice(item.price, locale, currency),
      price_in_cents: item.price,
      description: item.description,
      category: getItemCategory(item),
      image: toPrimaryImage(item),
    };

    if (item.type === 'bundle' || item.type === 'device') {
      orderItem.bundle_products = [];

      item.items.forEach(bundleItem => {
        orderItem.bundle_products?.push({
          name: bundleItem.name,
          sku: bundleItem.sku,
          image: toPrimaryImage(bundleItem),
        });
      });
    }

    orderItems.push(orderItem);
  });

  return orderItems;
};

const toFormattedPrice = (
  priceInCents: number,
  locale: string,
  currency: string,
): string => {
  return (priceInCents / 100).toLocaleString(locale, {
    style: 'currency',
    currency: currency,
  });
};

const getItemCategory = (item: Item): string => {
  if (hasOrIsBike(item) || hasOrIsRefurbishedBike(item)) {
    return 'bike';
  } else if (hasOrIsBikePlus(item) || hasOrIsRefurbishedBikePlus(item)) {
    return 'bike-plus';
  } else if (hasOrIsGuide(item)) {
    return 'guide';
  } else if (hasOrIsTread(item)) {
    return 'tread';
  } else if (hasOrIsTreadPlus(item)) {
    return 'tread-plus';
  } else if (hasOrIsRow(item)) {
    return 'row';
  } else if (hasOrIsAccessory(item)) {
    return 'accessory';
  } else {
    return '';
  }
};
