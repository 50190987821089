import { pathOr, contains, all, equals, not } from 'ramda';
import type { SagaIterator } from 'redux-saga';
import {
  call,
  delay,
  getContext,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import type { Client } from '@peloton/api';
import { CLIENT_CONTEXT } from '@peloton/api';
import type { UserReducerActionType } from '@peloton/auth/redux';
import { CartActions, selectCartLoading, selectCart } from '@ecomm/cart/redux';
import { getCartRemainingTotal } from '@ecomm/cart/redux/selectors/base';
import { OrderType } from '@ecomm/checkout/models';
import type {
  EnteredEmailAction,
  SelectPaymentMethodAction,
} from '@ecomm/checkout/redux';
import {
  selectCheckoutBilling,
  selectPaymentMethod,
  updateBillingInfo,
  ShippingActions,
  getShippingUIState,
  submitOrder,
  updateShipping,
  PaymentActions,
} from '@ecomm/checkout/redux';
import { getIsToggleActive } from '@ecomm/feature-toggle';
import type { Token, ContactInfo } from '@ecomm/models';
import { getException, isLoading, PaymentMethod } from '@ecomm/models';

import { toAddressee } from '@ecomm/models/ContactInfo';
import { createPaymentToken } from '@ecomm/payment/api';
import { smoothScroll } from '@ecomm/scroll';
import { getKlarna, authorize, loadWidget } from '../klarna/api';
import { Partners } from '../models/Partners';
import type { AuthorizeFinancingAction, CloseLegalModal, KlarnaStart } from '../redux';
import {
  Actions,
  authorizeFinancing,
  klarnaLoad,
  klarnaLoaded,
  klarnaPaymentAvailable,
  klarnaPaymentUnavailable,
  klarnaStart,
  legalToggleAcceptance,
  openKlarnaWidget,
  openLegalModal,
  submitFinancingFailure,
} from '../redux';
import {
  getFinancing,
  isKlarnaSDKLoaded,
  isLegalAccepted,
  usesKlarnaIntegration,
} from '../selectors';
import { initializeKlarnaSaga } from './loadKlarna';

export type CheckoutUpdatedAction =
  | UserReducerActionType.LOGIN_SUCCESS
  | EnteredEmailAction;

export const klarnaAuthorizeSaga = function* ({
  payload: { paymentMethod },
}: AuthorizeFinancingAction): SagaIterator {
  const cartSummary = yield select(selectCart);
  const userInfo = {
    email: pathOr('', ['cart', 'email'], cartSummary),
    ...pathOr({}, ['cart', 'shipping'], cartSummary),
  };

  const { errors, success, token: tokenId } = yield call(
    authorize,
    paymentMethod,
    userInfo,
  );
  const token: Token = {
    kind: Partners.Klarna,
    id: tokenId,
  };

  if (success) {
    yield put(
      updateBillingInfo({
        method: PaymentMethod.Financing,
        token,
      }),
    );

    yield put(
      submitOrder({
        kind: OrderType.PaymentGateway,
        method: PaymentMethod.Financing,
        token,
      }),
    );
  } else {
    yield put(klarnaPaymentUnavailable(errors));
  }
  yield put(selectPaymentMethod(PaymentMethod.Unknown, Partners.None));
};

export const klarnaLoadSaga = function* (client: Client, paymentMethod: PaymentMethod) {
  try {
    yield call(initializeKlarnaSaga);
    yield put(klarnaLoad());

    yield call(updatePaymentToken, client);

    const { success, errors } = yield call(loadWidget, paymentMethod);

    yield put(klarnaLoaded());
    yield put(openKlarnaWidget());

    if (success) {
      yield put(klarnaPaymentAvailable());
    } else {
      yield put(klarnaPaymentUnavailable(errors));
    }
  } catch (error) {
    yield call(klarnaResetSaga);
  }
};

export const klarnaResetSaga = function* () {
  yield put(selectPaymentMethod(PaymentMethod.Unknown, Partners.None));
  yield put(smoothScroll());
};

const paymentMethodsThatRequireLegalModal = [PaymentMethod.Financing];

export const klarnaSelectedSaga = function* (
  client: Client,
  action: KlarnaStart,
): SagaIterator {
  const shippingUIState = yield select(getShippingUIState);
  if (getException(shippingUIState)) {
    yield call(klarnaResetSaga);
    return;
  }
  const legalAccepted = yield select(isLegalAccepted);
  const legalModalActive = yield select(
    getIsToggleActive('showFinancingLegalModalOnCheckout'),
  );

  const showLegalModal = all(equals(true), [
    contains(action.payload.paymentMethod, paymentMethodsThatRequireLegalModal),
    legalModalActive,
    not(legalAccepted),
  ]);

  if (showLegalModal) {
    yield put(openLegalModal());
  } else {
    yield call(klarnaLoadSaga, client, action.payload.paymentMethod);
  }
};

const supportedPaymentMethods = [
  PaymentMethod.KlarnaDirectDebit,
  PaymentMethod.KlarnaBankTransfer,
  PaymentMethod.KlarnaInvoice,
];

const shouldKlarnaHandleThat = function* (
  action: SelectPaymentMethodAction,
): SagaIterator {
  if (
    action.payload.paymentMethod === PaymentMethod.Financing &&
    usesKlarnaIntegration(action.payload.financingPartner)
  ) {
    return true;
  }

  return contains(action.payload.paymentMethod, supportedPaymentMethods);
};

export const maybeSelectedKlarna = function* (
  action: SelectPaymentMethodAction,
): SagaIterator {
  const shouldHandle = yield call(shouldKlarnaHandleThat, action);

  if (shouldHandle) {
    yield put(klarnaStart(action.payload.paymentMethod));
    yield takeEvery(
      [ShippingActions.UpdateFailure, ShippingActions.UpdateInvalid],
      klarnaResetSaga,
    );
  }
};

export const closeLegalModalSaga = function* (action: CloseLegalModal) {
  if (!action.payload.accepted) {
    yield put(legalToggleAcceptance(false));
    yield put(selectPaymentMethod(PaymentMethod.Unknown, Partners.None));
  }
};

export const updatePaymentToken = function* (client: Client): SagaIterator {
  const shippingStatus = yield select(getShippingUIState);
  if (isLoading(shippingStatus)) {
    yield take(ShippingActions.UpdateSuccess);
  }

  try {
    const paymentAmountInCents = yield select(getCartRemainingTotal);
    const { clientToken } = yield call(
      createPaymentToken,
      client,
      Partners.Klarna,
      true,
      paymentAmountInCents,
    );

    yield call(getKlarna().Payments.init, { client_token: clientToken });
  } catch (error) {
    // recalculate taxes, shipping, and reload the cart for the
    // case that the cart was changed in another browser window
    const cartSummary = yield select(selectCart);
    yield put(updateShipping(cartSummary.cart.shipping));

    yield call(klarnaResetSaga);
    yield put(submitFinancingFailure(error));
  }
};

export const submitSaga = function* (
  paymentMethod: PaymentMethod,
  contactInfo: ContactInfo,
): SagaIterator {
  try {
    const financingState = yield select(getFinancing);

    if (!isKlarnaSDKLoaded(financingState)) {
      yield call(initializeKlarnaSaga);
    }

    // This delay is necessary because it mitigates the race condition
    // between blurring an input field and clicking the submit financing
    // button both trigger cart updates - an edge case that will be fixed
    // by rebuilding checkout w/o Formik.
    yield delay(500);

    const cartLoading = yield select(selectCartLoading);
    if (!cartLoading) {
      yield call(submitCartReadySaga, paymentMethod, contactInfo);
    } else {
      while (true) {
        yield take([CartActions.REQUEST_SUCCESS, CartActions.REQUEST_INITIAL_SUCCESS]);
        yield call(submitCartReadySaga, paymentMethod, contactInfo);
      }
    }
  } catch (error) {
    yield call(klarnaResetSaga);
    yield put(submitFinancingFailure(error));
  }
};

export const submitCartReadySaga = function* (
  paymentMethod: PaymentMethod,
  contactInfo: ContactInfo,
): SagaIterator {
  const billingInfo = yield select(selectCheckoutBilling);

  yield put(
    updateBillingInfo({
      ...billingInfo,
      ...pathOr({}, ['address'], contactInfo),
      // toAddressee takes a { name: { first, last } }
      addressee: toAddressee(contactInfo),
    }),
  );

  yield put(authorizeFinancing(paymentMethod));
};

export const klarnaSaga = function* (): SagaIterator {
  const client = yield getContext(CLIENT_CONTEXT);

  yield takeEvery(Actions.AuthorizeFinancing, klarnaAuthorizeSaga);

  yield takeEvery(Actions.KlarnaStart, klarnaSelectedSaga, client);
  yield takeEvery(PaymentActions.SelectPaymentMethod, maybeSelectedKlarna);

  yield takeEvery(Actions.CloseLegalModal, closeLegalModalSaga);
};
