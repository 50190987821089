import { pathOr } from 'ramda';
import type { Entities } from '../models';
import type { ProductOption } from '../models/ProductOption';

export enum ActionType {
  Add = 'ecomm/shop/ADD_PRODUCT_OPTIONS',
}

export type State = Entities<ProductOption>;

export const defaultState = {};

const reducer = (state: State = defaultState, action: Action) => {
  switch (action.type) {
    case ActionType.Add: {
      const productOptions = pathOr({}, ['payload', 'productOptions'], action);
      return {
        ...state,
        ...productOptions,
      };
    }

    default:
      return state;
  }
};

export default reducer;

export type ReducerState = {
  productOptions: State;
};

export const addProductOptions = (
  productOptions: Entities<ProductOption>,
): AddProductOptionsAction => ({
  type: ActionType.Add,
  payload: { productOptions },
});

type AddProductOptionsAction = {
  type: ActionType.Add;
  payload: { productOptions: Entities<ProductOption> };
};

type Action = AddProductOptionsAction;
